import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import writeXlsxFile from "write-excel-file";

import { logout } from "../actions/adminActions";
import {
  deleteCheckin,
  deleteCheckins,
  downloadCheckins,
  focCheckin,
  getCheckins,
} from "../actions/checkinActions";
import {
  DELETE_CHECKINS_RESET,
  DELETE_CHECKIN_RESET,
  DOWNLOAD_CHECKINS_RESET,
  FOC_CHECKIN_RESET,
  GET_CHECKINS_RESET,
} from "../constants/checkinConstants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";
import { VEHICLES } from "../constants";
import { tokens } from "../theme";
import { getParkingsForAdmins, getParkings } from "../actions/parkingActions";
import {
  GET_PARKINGS_FOR_ADMINS_RESET,
  GET_PARKINGS_RESET,
} from "../constants/parkingConstants";

const Checkins = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);
  const [ins, setIns] = useState(0);
  const [outs, setOuts] = useState(0);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [checkinToFoc, setCheckinToFoc] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [isCheckedOut, setIsCheckedOut] = useState("");
  const [isWhitelisted, setIsWhitelisted] = useState("");
  const [isFoc, setIsFoc] = useState("");
  const [isDelivery, setIsDelivery] = useState("");
  const [from, setFrom] = useState(moment().subtract(1, "day"));
  const [to, setTo] = useState(moment());
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkinToDelete, setCheckinToDelete] = useState(null);
  const [isHoliday, setIsHoliday] = useState("");
  const [deletetionArray, setDeletionArray] = useState([]);
  const [allParkings, setAllParkings] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [stats, setStats] = useState({ car: {}, bike: {} });
  const [subAmount, setSubAmount] = useState(0);
  const [visitorAmount, setVisitorAmount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    // setLoading(true);
    dispatch(getParkingsForAdmins());
    dispatch(getParkings(1));
  }, []);

  const getParkingsForAdminsInfo = useSelector(
    (state) => state.getParkingsInfo
  );
  //  TODO: change this logic, invalid for role based login
  const {
    loadingGetParkingsForAdmins,
    getParkingsData,
    errorGetParkingsForAdmins,
  } = getParkingsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_RESET });
    if (getParkingsData) {
      setAllParkings(getParkingsData.data);
      // setLoading(false);
    } else if (errorGetParkingsForAdmins) {
      toast.error(errorGetParkingsForAdmins);
      // setLoading(false);
    }
  }, [getParkingsData, errorGetParkingsForAdmins]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getCheckins(
        page,
        vehicleNumber,
        vehicleType,
        isCheckedOut,
        isWhitelisted,
        isFoc,
        isDelivery,
        moment(from).toISOString(),
        moment(to).toISOString(),
        isHoliday,
        parkings
      )
    );
  }, [page]);

  const getCheckinsInfo = useSelector((state) => state.getCheckinsInfo);
  const { loadingGetCheckins, getCheckinsData, errorGetCheckins } =
    getCheckinsInfo;

  useEffect(() => {
    dispatch({ type: GET_CHECKINS_RESET });
    if (getCheckinsData) {
      setData(getCheckinsData.data);
      setIns(getCheckinsData.ins);
      setOuts(getCheckinsData.outs);
      setAmount(getCheckinsData.amount);
      setTotal(getCheckinsData.total);
      setPage(+getCheckinsData.page);
      setStats(getCheckinsData.stats);
      setSubAmount(getCheckinsData.subAmount);
      setVisitorAmount(getCheckinsData.visitorAmount);
      setLoading(false);
    } else if (errorGetCheckins) {
      toast.error(errorGetCheckins);
      setLoading(false);
    }
  }, [getCheckinsData, errorGetCheckins]);

  const columns = [
    adminInfo.type === "Admin" && {
      field: "mobile",
      headerName: "Mobile",
      valueGetter: ({ value }) => (value ? value : "N/A"),
      minWidth: 200,
    },
    { field: "vehicleNumber", headerName: "Vehicle Number", minWidth: 200 },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      minWidth: 200,
    },
    {
      field: "helmetQuantity",
      headerName: "Helmet Quantity",
      minWidth: 200,
    },
    { field: "amount", headerName: "Amount", minWidth: 200 },
    {
      field: "inTime",
      headerName: "In Time",
      valueGetter: ({ value }) =>
        value && moment(value).format("DD-MM-YYYY hh:mm A"),
      minWidth: 200,
    },
    {
      field: "outTime",
      headerName: "Out Time",
      valueGetter: ({ value }) =>
        value && moment(value).format("DD-MM-YYYY hh:mm A"),
      minWidth: 200,
    },
    {
      field: "isCheckedOut",
      headerName: "Checked Out",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
    {
      field: "checkInUser",
      headerName: "Checkin User",
      valueGetter: ({ value }) => (value ? value.username : "N/A"),
      minWidth: 200,
    },
    {
      field: "checkOutUser",
      headerName: "Checkout User",
      valueGetter: ({ value }) => (value ? value.username : "N/A"),
      minWidth: 200,
    },
    {
      field: "pass",
      headerName: "Pass",
      valueGetter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 200,
    },
    {
      field: "location",
      headerName: "Location",
      valueGetter: ({ value }) =>
        value ? `${value.name} - ${value.businessType}` : "N/A",
      minWidth: 250,
    },
    {
      field: "parking",
      headerName: "Parking",
      valueGetter: ({ value }) => (value ? value.name : "N/A"),
      minWidth: 200,
    },
    (adminInfo.type === "Admin" || adminInfo.type === "Corporate") && {
      field: "parkingType",
      headerName: "Parking Type",
      valueGetter: ({ value }) => (value ? value : "N/A"),
      minWidth: 200,
    },
    (adminInfo.type === "Admin" || adminInfo.type === "Corporate") && {
      field: "isWhitelisted",
      headerName: "Whitelisted",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
    (adminInfo.type === "Admin" || adminInfo.type === "Corporate") && {
      field: "isFoc",
      headerName: "FOC",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
    (adminInfo.type === "Admin" || adminInfo.type === "Corporate") && {
      field: "focBy",
      headerName: "FOC By",
      valueGetter: ({ value }) => (value ? value.name : "N/A"),
      minWidth: 200,
    },
    (adminInfo.type === "Admin" || adminInfo.type === "Corporate") && {
      field: "isDelivery",
      headerName: "Delivery",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
    adminInfo.type === "Unorganised" && {
      field: "isHoliday",
      headerName: "Is Holiday",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
    adminInfo.type === "Admin" && {
      field: "uuid",
      headerName: "UUID",
      valueGetter: ({ value }) => value && value,
      minWidth: 250,
    },
    adminInfo.type === "Admin" && {
      field: "isDeleted",
      headerName: "Is Deleted",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
  ];

  const focHandler = (item) => {
    if (adminInfo.type === "Admin" || adminInfo.type === "Corporate") {
      if (adminInfo.role === "Parking") {
        toast.error("You are not authorized to FOC this vehicle");
        return;
      }

      if (item.location?.businessType !== "Corporate") {
        toast.error("You cannot FOC this vehicle as it is of Unorganised");
        return;
      }

      // if (!item.parking?.modules?.foc) {
      //   toast.error('You cannot FOC this vehicle as it is not from a company')
      //   return
      // }

      setCheckinToFoc(item);
    } else {
      if (item.location?.businessType !== "Unorganised") {
        toast.error("You cannot delete this vehicle as it is of Corporate");
        return;
      }

      setCheckinToDelete(item);
    }
  };

  const focCheckinInfo = useSelector((state) => state.focCheckinInfo);
  const { loadingFocCheckin, focCheckinData, errorFocCheckin } = focCheckinInfo;

  useEffect(() => {
    dispatch({ type: FOC_CHECKIN_RESET });
    if (focCheckinData) {
      toast.success("Vehicle marked FOC successfully");

      const objIndex = data.findIndex((obj) => obj._id == checkinToFoc._id);
      data[objIndex].isFoc = true;
      data[objIndex].focBy = focCheckinData.focBy;

      setCheckinToFoc(null);
    } else if (errorFocCheckin) {
      toast.error(errorFocCheckin);
    }
  }, [focCheckinData, errorFocCheckin]);

  const deleteCheckinInfo = useSelector((state) => state.deleteCheckinInfo);
  const { loadingDeleteCheckin, deleteCheckinData, errorDeleteCheckin } =
    deleteCheckinInfo;

  useEffect(() => {
    dispatch({ type: DELETE_CHECKIN_RESET });
    if (deleteCheckinData) {
      toast.success("Vehicle deleted successfully");

      const objIndex = data.findIndex((obj) => obj._id == checkinToDelete._id);
      data[objIndex].isDeleted = true;

      setCheckinToDelete(null);
    } else if (errorDeleteCheckin) {
      toast.error(errorDeleteCheckin);
    }
  }, [deleteCheckinData, errorDeleteCheckin]);

  const downloadReport = () => {
    dispatch(
      downloadCheckins(
        vehicleNumber,
        vehicleType,
        isCheckedOut,
        isWhitelisted,
        isFoc,
        isDelivery,
        moment(from).toISOString(),
        moment(to).toISOString(),
        isHoliday,
        parkings
      )
    );
  };

  const downloadCheckinsInfo = useSelector(
    (state) => state.downloadCheckinsInfo
  );
  const {
    loadingDownloadCheckins,
    downloadCheckinsData,
    errorDownloadCheckins,
  } = downloadCheckinsInfo;

  useEffect(() => {
    dispatch({ type: DOWNLOAD_CHECKINS_RESET });
    if (downloadCheckinsData) {
      toast.success("Checkins downloaded successfully");
      downloadExcel(downloadCheckinsData);
    } else if (errorDownloadCheckins) {
      toast.error(errorDownloadCheckins);
    }
  }, [downloadCheckinsData, errorDownloadCheckins]);

  const downloadExcel = async (downloadData) => {
    await writeXlsxFile(downloadData, {
      fileName: "Checkins.xlsx",
    });
  };

  const onSelectionModelChange = (items) => {
    if (items.length > 0) {
      setDeletionArray(items);
    } else {
      setDeletionArray([]);
    }
  };

  const deleteCheckinsInfo = useSelector((state) => state.deleteCheckinsInfo);
  const { loadingDeleteCheckins, deleteCheckinsData, errorDeleteCheckins } =
    deleteCheckinsInfo;

  useEffect(() => {
    dispatch({ type: DELETE_CHECKINS_RESET });
    if (deleteCheckinsData) {
      toast.success(deleteCheckinsData.msg);

      onSelectionModelChange([]);
      dispatch(
        getCheckins(
          page,
          vehicleNumber,
          vehicleType,
          isCheckedOut,
          isWhitelisted,
          isFoc,
          isDelivery,
          moment(from).toISOString(),
          moment(to).toISOString(),
          isHoliday,
          parkings
        )
      );
      setLoading(true);
    } else if (errorDeleteCheckins) {
      toast.error(errorDeleteCheckins);
    }
  }, [deleteCheckinsData, errorDeleteCheckins]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CHECKINS" subtitle="List of checkins" />
        <Box>
          {deletetionArray.length > 0 && (
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ fontSize: 14, marginRight: 4 }}
              disabled={
                loadingGetCheckins ||
                loadingFocCheckin ||
                loadingDeleteCheckin ||
                loadingDownloadCheckins ||
                loadingDeleteCheckins
              }
              onClick={() => dispatch(deleteCheckins(deletetionArray))}
            >
              Delete
            </Button>
          )}
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ fontSize: 14, marginRight: 4 }}
            disabled={
              loadingGetCheckins ||
              loadingFocCheckin ||
              loadingDeleteCheckin ||
              loadingDownloadCheckins ||
              loadingDeleteCheckins
            }
            onClick={downloadReport}
          >
            Download
          </Button>
          <Button
            type="submit"
            color="warning"
            variant="contained"
            sx={{ fontSize: 14 }}
            disabled={
              loadingGetCheckins ||
              loadingFocCheckin ||
              loadingDeleteCheckin ||
              loadingDownloadCheckins ||
              loadingDeleteCheckins
            }
            onClick={() => setShowFiltersModal(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            sx={{ width: "100%" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              marginBottom={-2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 2,
                borderRadius: 2,
                flex: 1,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 18,
                  }}
                >
                  Vehicles
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 18,
                  }}
                >
                  {total}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  Checkins
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  {ins}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  Checkouts
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  {outs}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              marginBottom={-2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 2,
                borderRadius: 2,
                flex: 1,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 18,
                  }}
                >
                  Total Amount
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 18,
                  }}
                >
                  Rs. {amount}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  Amount - Subrate
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  Rs. {subAmount}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  Amount - Visitors
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: 14,
                  }}
                >
                  Rs. {visitorAmount}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            sx={{ width: "100%", marginTop: "40px" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              marginBottom={-2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 2,
                borderRadius: 2,
                flex: 1,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 18 }}
                >
                  Total Cars
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 18 }}
                >
                  {stats["car"]["total"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  Whitelisted
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["car"]["whitelisted"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  FOC
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["car"]["foc"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  SubRate Cars
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["car"]["subrate"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  Pass
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["car"]["pass"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  Visitors
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["car"]["total"] -
                    stats["car"]["pass"] -
                    stats["car"]["subrate"] -
                    stats["car"]["foc"] -
                    stats["car"]["whitelisted"]}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              marginBottom={-2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 2,
                borderRadius: 2,
                flex: 1,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 18 }}
                >
                  Total Bikes
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 18 }}
                >
                  {stats["bike"]["total"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  Whitelisted
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["bike"]["whitelisted"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  FOC
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["bike"]["foc"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  SubRate
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["bike"]["subrate"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  Pass
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["bike"]["pass"]}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  Visitors
                </Typography>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ alignSelf: "flex-start", fontSize: 14 }}
                >
                  {stats["bike"]["total"] -
                    stats["car"]["pass"] -
                    stats["bike"]["subrate"] -
                    stats["bike"]["foc"] -
                    stats["bike"]["whitelisted"]}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Table
            onPageChange={(newPage) => {
              setPage(newPage + 1);
            }}
            total={total}
            rows={data}
            columns={columns}
            page={page}
            getRowId={(row) => row._id}
            onRowClick={(row) => focHandler(row.row)}
            checkboxSelection={true}
            onSelectionModelChange={onSelectionModelChange}
          />
        </>
      )}
      {checkinToFoc && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setCheckinToFoc(null);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              FOC Confirmation
            </Typography>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h3"
              marginTop={2}
              marginBottom={2}
            >
              Are you sure, you want to mark this vehicle (
              {checkinToFoc.vehicleNumber}) as FOC?
            </Typography>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ fontSize: 14 }}
              onClick={() => {
                dispatch(focCheckin(checkinToFoc._id));
              }}
              disabled={
                loadingGetCheckins ||
                loadingFocCheckin ||
                loadingDeleteCheckin ||
                loadingDeleteCheckins
              }
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      )}
      {checkinToDelete && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setCheckinToDelete(null);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              Delete Confirmation
            </Typography>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h3"
              marginTop={2}
              marginBottom={2}
            >
              Are you sure, you want to delete this vehicle (
              {checkinToDelete.vehicleNumber})?
            </Typography>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ fontSize: 14 }}
              onClick={() => {
                dispatch(deleteCheckin(checkinToDelete._id));
              }}
              disabled={
                loadingGetCheckins ||
                loadingFocCheckin ||
                loadingDeleteCheckin ||
                loadingDeleteCheckins
              }
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      )}
      {showFiltersModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setShowFiltersModal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isNonMobile ? 800 : 350,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              Filters
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Parkings
                    </Typography>
                    <Select
                      fullWidth
                      multiple
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={parkings}
                      label="Parkings"
                      onChange={(e) => setParkings(e.target.value)}
                      name="parkings"
                      disabled={
                        loadingGetCheckins ||
                        loadingFocCheckin ||
                        loadingDeleteCheckin ||
                        loadingDeleteCheckins
                      }
                    >
                      {allParkings.map((el) => (
                        <MenuItem
                          value={el._id.toString()}
                          key={el._id}
                          selected={parkings.includes(el._id.toString())}
                        >
                          {el.name} ({el.location.name} -{" "}
                          {el.location.businessType})
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Vehicle Number
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Vehicle Number"
                      onChange={(e) => setVehicleNumber(e.target.value)}
                      value={vehicleNumber}
                      name="vehicleNumber"
                      disabled={
                        loadingGetCheckins ||
                        loadingFocCheckin ||
                        loadingDeleteCheckin ||
                        loadingDeleteCheckins
                      }
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Vehicle Type
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={vehicleType}
                      label="Vehicle Type"
                      onChange={(e) => setVehicleType(e.target.value)}
                      name="vehicleType"
                      disabled={
                        loadingGetCheckins ||
                        loadingFocCheckin ||
                        loadingDeleteCheckin ||
                        loadingDeleteCheckins
                      }
                    >
                      {VEHICLES.map((el) => (
                        <MenuItem value={el.toUpperCase()}>
                          {el.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Is Checked Out
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={isCheckedOut}
                      label="Is Checked Out"
                      onChange={(e) => setIsCheckedOut(e.target.value)}
                      name="isCheckedOut"
                      disabled={
                        loadingGetCheckins ||
                        loadingFocCheckin ||
                        loadingDeleteCheckin ||
                        loadingDeleteCheckins
                      }
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </Box>
                  {(adminInfo.type === "Admin" ||
                    adminInfo.type === "Unorganised") && (
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      <Typography variant="h6" component="h4">
                        Is Holiday
                      </Typography>
                      <Select
                        fullWidth
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={isHoliday}
                        label="Vehicle Type"
                        onChange={(e) => setIsHoliday(e.target.value)}
                        name="isWhitelisted"
                        disabled={
                          loadingGetCheckins ||
                          loadingFocCheckin ||
                          loadingDeleteCheckin ||
                          loadingDeleteCheckins
                        }
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Box>
                  )}
                </Box>
                {(adminInfo.type === "Admin" ||
                  adminInfo.type === "Corporate") && (
                  <Box
                    display="flex"
                    flexDirection={isNonMobile ? "row" : "column"}
                    gridColumn="span 4"
                    marginTop={-2}
                    gap={isNonMobile ? 4 : 2}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      <Typography variant="h6" component="h4">
                        Is Whitelisted
                      </Typography>
                      <Select
                        fullWidth
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={isWhitelisted}
                        label="Vehicle Type"
                        onChange={(e) => setIsWhitelisted(e.target.value)}
                        name="isWhitelisted"
                        disabled={
                          loadingGetCheckins ||
                          loadingFocCheckin ||
                          loadingDeleteCheckin ||
                          loadingDeleteCheckins
                        }
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      <Typography variant="h6" component="h4">
                        Is FOC
                      </Typography>
                      <Select
                        fullWidth
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={isFoc}
                        label="Is FOC"
                        onChange={(e) => setIsFoc(e.target.value)}
                        name="isFoc"
                        disabled={
                          loadingGetCheckins ||
                          loadingFocCheckin ||
                          loadingDeleteCheckin ||
                          loadingDeleteCheckins
                        }
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      <Typography variant="h6" component="h4">
                        Is Delivery
                      </Typography>
                      <Select
                        fullWidth
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={isDelivery}
                        label="Is Delivery"
                        onChange={(e) => setIsDelivery(e.target.value)}
                        name="isDelivery"
                        disabled={
                          loadingGetCheckins ||
                          loadingFocCheckin ||
                          loadingDeleteCheckin ||
                          loadingDeleteCheckins
                        }
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      From
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="datetime-local"
                      label="From"
                      onChange={(e) => setFrom(e.target.value)}
                      value={from}
                      name="from"
                      disabled={
                        loadingGetCheckins ||
                        loadingFocCheckin ||
                        loadingDeleteCheckin ||
                        loadingDeleteCheckins
                      }
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      To
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="datetime-local"
                      label="To"
                      onChange={(e) => setTo(e.target.value)}
                      value={to}
                      name="to"
                      disabled={
                        loadingGetCheckins ||
                        loadingFocCheckin ||
                        loadingDeleteCheckin ||
                        loadingDeleteCheckins
                      }
                    />
                  </Box>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setShowFiltersModal(false);
                    setLoading(true);
                    dispatch(
                      getCheckins(
                        page,
                        vehicleNumber,
                        vehicleType,
                        isCheckedOut,
                        isWhitelisted,
                        isFoc,
                        isDelivery,
                        moment(from).toISOString(),
                        moment(to).toISOString(),
                        isHoliday,
                        parkings
                      )
                    );
                  }}
                  disabled={
                    loadingGetCheckins ||
                    loadingFocCheckin ||
                    loadingDeleteCheckin ||
                    loadingDownloadCheckins ||
                    loadingDeleteCheckins
                  }
                >
                  Apply
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Checkins;
