import { Box, Button, Select, TextField, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  addAdmin,
  editAdmin,
  generatePasswordAdmin,
  getAdmins,
  logout,
} from "../actions/adminActions";
import {
  ADD_ADMIN_RESET,
  EDIT_ADMIN_RESET,
  GENERATE_PASSWORD_ADMIN_RESET,
  GET_ADMINS_RESET,
} from "../constants/adminConstants";
import { getLocationsForAdmins } from "../actions/locationActions";
import { GET_LOCATIONS_FOR_ADMINS_RESET } from "../constants/locationConstants";
import { useNavigate } from "react-router-dom";
import { getParkingsForAdmins } from "../actions/parkingActions";
import { GET_PARKINGS_FOR_ADMINS_RESET } from "../constants/parkingConstants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";

const Admins = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [adminToEdit, setAdminToEdit] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(false);
  const [locations, setLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [parking, setParking] = useState([]);
  const [allParkings, setAllParkings] = useState([]);
  const [addAdminModal, setAddAdminModdal] = useState(false);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [roles, setRoles] = useState([]);
  const [filteredAllLocation, setFilteredAllLocations] = useState([]);
  const [filteredAllParkings, setFilteredAllParkings] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterRole, setFilterRole] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (
      !adminInfo ||
      adminInfo?.role !== "Super Admin" ||
      adminInfo?.role === "Admin"
    ) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    dispatch(getAdmins(page, filterName, filterEmail, filterRole));
  }, [page]);

  const getAdminsInfo = useSelector((state) => state.getAdminsInfo);
  const { loadingGetAdmins, getAdminsData, errorGetAdmins } = getAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_ADMINS_RESET });
    if (getAdminsData) {
      setData(getAdminsData.data);
      setTotal(getAdminsData.total);
      setPage(+getAdminsData.page);
      setLoading(false);
    } else if (errorGetAdmins) {
      toast.error(errorGetAdmins);
      setLoading(false);
    }
  }, [getAdminsData, errorGetAdmins]);

  useEffect(() => {
    dispatch(getLocationsForAdmins());
    setLoading(true);
  }, []);

  const getLocationsForAdminsInfo = useSelector(
    (state) => state.getLocationsForAdminsInfo
  );
  const {
    loadingGetLocationsForAdmins,
    getLocationsForAdminsData,
    errorGetLocationsForAdmins,
  } = getLocationsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_FOR_ADMINS_RESET });
    if (getLocationsForAdminsData) {
      setAllLocations(getLocationsForAdminsData);
      setLoading(false);
    } else if (errorGetLocationsForAdmins) {
      toast.error(errorGetLocationsForAdmins);
      setLoading(false);
    }
  }, [getLocationsForAdminsData, errorGetLocationsForAdmins]);

  useEffect(() => {
    dispatch(getParkingsForAdmins());
    setLoading(true);
  }, []);

  const getParkingsForAdminsInfo = useSelector(
    (state) => state.getParkingsForAdminsInfo
  );
  const {
    loadingGetParkingsForAdmins,
    getParkingsForAdminsData,
    errorGetParkingsForAdmins,
  } = getParkingsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_FOR_ADMINS_RESET });
    if (getParkingsForAdminsData) {
      setAllParkings(getParkingsForAdminsData);
      setLoading(false);
    } else if (errorGetParkingsForAdmins) {
      toast.error(errorGetParkingsForAdmins);
      setLoading(false);
    }
  }, [getParkingsForAdminsData, errorGetParkingsForAdmins]);

  useEffect(() => {
    if (adminToEdit) {
      setName(adminToEdit.name);
      setEmail(adminToEdit.email);
      setStatus(adminToEdit.status);
      setLocations(adminToEdit.locations);
      setRole(adminToEdit.role);
      setParking(adminToEdit.parking ? adminToEdit.parking : "");
      setType(adminToEdit.type);
    } else {
      setName("");
      setEmail("");
      setStatus(false);
      setLocations([]);
      setRole("");
      setParking([]);
      setType("");
    }
  }, [adminToEdit]);

  const handleUpdate = () => {
    if (!name || !email) {
      toast.error("All the fields are mandatory");
    } else {
      if (role === "Parking" && !parking) {
        toast.error("Company is mandatory");
      } else if (role !== "Parking" && !locations) {
        toast.error("Vendor is mandatory");
      } else {
        dispatch(
          editAdmin(adminToEdit._id, name, email, status, locations, parking)
        );
      }
    }
  };

  const editAdminInfo = useSelector((state) => state.editAdminInfo);
  const { loadingEditAdmin, editAdminData, errorEditAdmin } = editAdminInfo;

  useEffect(() => {
    dispatch({ type: EDIT_ADMIN_RESET });
    if (editAdminData) {
      toast.success("Admin updated successfully");
      setData(
        data.map((obj) =>
          obj._id === editAdminData._id
            ? {
                ...obj,
                name: editAdminData.name,
                email: editAdminData.email,
                status: editAdminData.status,
                locations: editAdminData.locations,
                parking: editAdminData.parking,
                type: editAdminData.type,
              }
            : obj
        )
      );
      setAdminToEdit(null);
    } else if (errorEditAdmin) {
      toast.error(errorEditAdmin);
    }
  }, [editAdminData, errorEditAdmin]);

  const generatePasswordAdminInfo = useSelector(
    (state) => state.generatePasswordAdminInfo
  );
  const {
    loadingGeneratePasswordAdmin,
    generatePasswordAdminData,
    errorGeneratePasswordAdmin,
  } = generatePasswordAdminInfo;

  useEffect(() => {
    dispatch({ type: GENERATE_PASSWORD_ADMIN_RESET });
    if (generatePasswordAdminData) {
      toast.success("Password updated successfully");
      setData(
        data.map((obj) =>
          obj._id === generatePasswordAdminData._id
            ? {
                ...obj,
                pin: generatePasswordAdminData.pin,
              }
            : obj
        )
      );
      setAdminToEdit(null);
    } else if (errorGeneratePasswordAdmin) {
      toast.error(errorGeneratePasswordAdmin);
    }
  }, [generatePasswordAdminData, errorGeneratePasswordAdmin]);

  const addAdminHandler = () => {
    if (!name || !email || !role || !type) {
      toast.error("All the fields are mandatory");
    } else {
      if (role === "Parking" && !parking) {
        toast.error("Company is mandatory");
      } else if (role !== "Parking" && !locations) {
        toast.error("Vendor is mandatory");
      } else {
        dispatch(addAdmin(name, email, locations, role, parking, type));
      }
    }
  };

  const addAdminInfo = useSelector((state) => state.addAdminInfo);
  const { loadingAddAdmin, addAdminData, errorAddAdmin } = addAdminInfo;

  useEffect(() => {
    dispatch({ type: ADD_ADMIN_RESET });
    if (addAdminData) {
      toast.success("Admin created successfully");
      setAddAdminModdal(false);
      setName("");
      setEmail("");
      setRole("");
      setLocations([]);
      setParking([]);
      setType("");
      setData([...data, addAdminData]);
    } else if (errorAddAdmin) {
      toast.error(errorAddAdmin);
    }
  }, [addAdminData, errorAddAdmin]);

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      valueGetter: ({ value }) =>
        value === "Location"
          ? "Vendor"
          : value === "Parking"
          ? "Company"
          : value,
      width: 200,
    },
    {
      field: "pin",
      headerName: "Pin",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      valueGetter: ({ value }) =>
        value && value === true ? "Active" : "Not Active",
    },
  ];

  useEffect(() => {
    if (type === "Admin") {
      setRoles([
        { id: "Super Admin", name: "Super Admin" },
        { id: "Admin", name: "Admin" },
      ]);
      setFilteredAllLocations(allLocations);
      setFilteredAllParkings(allParkings);
    } else if (type === "Corporate") {
      setRoles([
        { id: "Location", name: "Vendor" },
        { id: "Parking", name: "Company" },
      ]);
      let ldt = [];
      allLocations.forEach((el) => {
        if (el.businessType === "Corporate") {
          ldt.push(el);
        }
      });
      setFilteredAllLocations(ldt);
      let pdt = [];
      allParkings.forEach((el) => {
        if (el.location.businessType === "Corporate") {
          pdt.push(el);
        }
      });
      setFilteredAllParkings(pdt);
    } else if (type === "Unorganised") {
      setRoles([{ id: "Parking", name: "Parking" }]);
      let ldt = [];
      allLocations.forEach((el) => {
        if (el.businessType === "Unorganised") {
          ldt.push(el);
        }
      });
      setFilteredAllLocations(ldt);
      let pdt = [];
      allParkings.forEach((el) => {
        if (el.location.businessType === "Unorganised") {
          pdt.push(el);
        }
      });
      setFilteredAllParkings(pdt);
    } else {
      setFilteredAllLocations(allLocations);
      setFilteredAllParkings(allParkings);
    }
  }, [type]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ADMINS" subtitle="List of administrators" />
        <Box>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ fontSize: 14, marginRight: 4 }}
            disabled={
              loadingEditAdmin ||
              loadingGeneratePasswordAdmin ||
              loadingAddAdmin ||
              loadingGetAdmins ||
              loadingGetLocationsForAdmins ||
              loadingGetParkingsForAdmins
            }
            onClick={() => setAddAdminModdal(true)}
          >
            Add Admin
          </Button>
          <Button
            type="submit"
            color="warning"
            variant="contained"
            sx={{ fontSize: 14 }}
            disabled={
              loadingAddAdmin ||
              loadingGetAdmins ||
              loadingGetLocationsForAdmins ||
              loadingGetParkingsForAdmins
            }
            onClick={() => setShowFiltersModal(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Table
          onPageChange={(newPage) => {
            setPage(newPage + 1);
          }}
          total={total}
          rows={data}
          columns={columns}
          page={page}
          onRowClick={(row) => setAdminToEdit(row.row)}
          getRowId={(row) => row.name}
        />
      )}
      {(adminToEdit || addAdminModal) && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setAdminToEdit(null);
            setAddAdminModdal(false);
            setName("");
            setEmail("");
            setRole("");
            setLocations([]);
            setParking([]);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Edit Admin
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={2}
                >
                  <Typography variant="p" component="p">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    name="name"
                    sx={{ gridColumn: "span 4" }}
                    disabled={
                      loadingEditAdmin ||
                      loadingGeneratePasswordAdmin ||
                      loadingAddAdmin ||
                      loadingGetAdmins ||
                      loadingGetLocationsForAdmins ||
                      loadingGetParkingsForAdmins
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant="p" component="p">
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                    sx={{ gridColumn: "span 4" }}
                    disabled={
                      loadingEditAdmin ||
                      loadingGeneratePasswordAdmin ||
                      loadingAddAdmin ||
                      loadingGetAdmins ||
                      loadingGetLocationsForAdmins ||
                      loadingGetParkingsForAdmins
                    }
                  />
                </Box>
                {adminToEdit && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Status
                    </Typography>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={(e) => setStatus(e.target.value)}
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Not Active</MenuItem>
                    </Select>
                  </Box>
                )}
                {addAdminModal && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridColumn="span 4"
                      gap={1}
                      marginTop={-2}
                    >
                      <Typography variant="p" component="p">
                        Type
                      </Typography>
                      <Select
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Role"
                        onChange={(e) => setType(e.target.value)}
                        sx={{ gridColumn: "span 4" }}
                        disabled={
                          loadingEditAdmin ||
                          loadingGeneratePasswordAdmin ||
                          loadingAddAdmin ||
                          loadingGetAdmins ||
                          loadingGetLocationsForAdmins ||
                          loadingGetParkingsForAdmins
                        }
                      >
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="Corporate">Corporate</MenuItem>
                        <MenuItem value="Unorganised">Unorganised</MenuItem>
                      </Select>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridColumn="span 4"
                      gap={1}
                      marginTop={-2}
                    >
                      <Typography variant="p" component="p">
                        Role
                      </Typography>
                      <Select
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Role"
                        onChange={(e) => setRole(e.target.value)}
                        sx={{ gridColumn: "span 4" }}
                        disabled={
                          loadingEditAdmin ||
                          loadingGeneratePasswordAdmin ||
                          loadingAddAdmin ||
                          loadingGetAdmins ||
                          loadingGetLocationsForAdmins ||
                          loadingGetParkingsForAdmins
                        }
                      >
                        {roles.map((rl) => (
                          <MenuItem value={rl.id}>{rl.name}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </>
                )}
                {(role === "Super Admin" ||
                  role === "Admin" ||
                  role === "Location") && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Locations
                    </Typography>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={locations}
                      label="Vendors"
                      multiple
                      onChange={(e) => setLocations(e.target.value)}
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    >
                      {filteredAllLocation.map((item) => (
                        <MenuItem
                          value={item._id}
                          key={item._id}
                          selected={locations.includes(item._id.toString())}
                        >
                          {item.name} ({item.businessType})
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
                {role === "Parking" && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Parking
                    </Typography>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={parking}
                      multiple={type === "Unorganised" ? true : false}
                      label="Company"
                      onChange={(e) => setParking(e.target.value)}
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    >
                      {filteredAllParkings.map((item) => (
                        <MenuItem
                          value={item._id}
                          key={item._id}
                          selected={parking.includes(item._id.toString())}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
              <Box
                mt="20px"
                display="flex"
                justifyContent="space-between"
                sx={{ height: 42 }}
              >
                {adminToEdit && (
                  <>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ fontSize: 14 }}
                      onClick={handleUpdate}
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    >
                      {loadingEditAdmin || loadingGeneratePasswordAdmin
                        ? "Please wait..."
                        : "Update"}
                    </Button>
                    <Button
                      type="submit"
                      color="error"
                      variant="contained"
                      sx={{ fontSize: 14 }}
                      onClick={() =>
                        dispatch(generatePasswordAdmin(adminToEdit._id))
                      }
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    >
                      {loadingEditAdmin || loadingGeneratePasswordAdmin
                        ? "Please wait..."
                        : "Password"}
                    </Button>
                  </>
                )}
                {addAdminModal && (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: 14 }}
                    onClick={addAdminHandler}
                    disabled={
                      loadingEditAdmin ||
                      loadingGeneratePasswordAdmin ||
                      loadingAddAdmin ||
                      loadingGetAdmins ||
                      loadingGetLocationsForAdmins ||
                      loadingGetParkingsForAdmins
                    }
                  >
                    {loadingAddAdmin ? "Please wait..." : "Add"}
                  </Button>
                )}
              </Box>
            </div>
          </Box>
        </Modal>
      )}
      {showFiltersModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setShowFiltersModal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isNonMobile ? 800 : 350,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              Filters
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onChange={(e) => setFilterName(e.target.value)}
                      value={filterName}
                      name="name"
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Email
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="email"
                      label="Email"
                      onChange={(e) => setFilterEmail(e.target.value)}
                      value={filterEmail}
                      name="email"
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Role
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterRole}
                      label="Role"
                      onChange={(e) => setFilterRole(e.target.value)}
                      name="role"
                      disabled={
                        loadingEditAdmin ||
                        loadingGeneratePasswordAdmin ||
                        loadingAddAdmin ||
                        loadingGetAdmins ||
                        loadingGetLocationsForAdmins ||
                        loadingGetParkingsForAdmins
                      }
                    >
                      <MenuItem value="Super Admin">Super Admin</MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Location">Vendor</MenuItem>
                      <MenuItem value="Parking">Company</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setLoading(true);
                    setShowFiltersModal(false);
                    dispatch(
                      getAdmins(page, filterName, filterEmail, filterRole)
                    );
                  }}
                  disabled={
                    loadingEditAdmin ||
                    loadingGeneratePasswordAdmin ||
                    loadingAddAdmin ||
                    loadingGetAdmins ||
                    loadingGetLocationsForAdmins ||
                    loadingGetParkingsForAdmins
                  }
                >
                  Apply
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Admins;
