import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

import { logout } from "../actions/adminActions";
import {
  addUser,
  editUser,
  generatePasswordUser,
  getUsers,
  resetDeviceIdUser,
} from "../actions/userActions";
import {
  ADD_USER_RESET,
  EDIT_USER_RESET,
  GENERATE_PASSWORD_USER_RESET,
  GET_USERS_RESET,
  RESET_DEVICE_ID_USER_RESET,
} from "../constants/userConstants";
import { getLocationsForAdmins } from "../actions/locationActions";
import { GET_LOCATIONS_FOR_ADMINS_RESET } from "../constants/locationConstants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";
import { getParkingsForAdmins } from "../actions/parkingActions";
import { GET_PARKINGS_FOR_ADMINS_RESET } from "../constants/parkingConstants";

const Users = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [userToEdit, setUserToEdit] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState(false);
  const [location, setLocation] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [addUserModal, setAddUserModdal] = useState(false);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredAllLocations, setFilteredAllLocations] = useState([]);
  const [parking, setParking] = useState([]);
  const [allParkings, setAllParkings] = useState([]);
  const [filteredAllParkings, setFilteredAllParkings] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterUsername, setFilterUsername] = useState("");
  const [filterRole, setFilterRole] = useState("");
  const [iotDeviceId, setIotDeviceId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    dispatch(getUsers(page, filterUsername, filterName, filterRole));
    setLoading(true);
  }, [page]);

  const getUsersInfo = useSelector((state) => state.getUsersInfo);
  const { loadingGetUsers, getUsersData, errorGetUsers } = getUsersInfo;

  useEffect(() => {
    dispatch({ type: GET_USERS_RESET });
    if (getUsersData) {
      setData(getUsersData.data);
      setTotal(getUsersData.total);
      setPage(+getUsersData.page);
      setLoading(false);
    } else if (errorGetUsers) {
      toast.error(errorGetUsers);
      setLoading(false);
    }
  }, [getUsersData, errorGetUsers]);

  useEffect(() => {
    if (adminInfo.type === "Admin" || adminInfo.type === "Corporate") {
      dispatch(getLocationsForAdmins());
      // setLoading(true);
    }
  }, []);

  const getLocationsForAdminsInfo = useSelector(
    (state) => state.getLocationsForAdminsInfo
  );
  const {
    loadingGetLocationsForAdmins,
    getLocationsForAdminsData,
    errorGetLocationsForAdmins,
  } = getLocationsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_FOR_ADMINS_RESET });
    if (getLocationsForAdminsData) {
      setAllLocations(getLocationsForAdminsData);
      // setLoading(false);
    } else if (errorGetLocationsForAdmins) {
      toast.error(errorGetLocationsForAdmins);
      // setLoading(false);
    }
  }, [getLocationsForAdminsData, errorGetLocationsForAdmins]);

  useEffect(() => {
    if (adminInfo.type === "Admin" || adminInfo.type === "Corporate") {
      dispatch(getParkingsForAdmins());
      // setLoading(true);
    }
  }, []);

  const getParkingsForAdminsInfo = useSelector(
    (state) => state.getParkingsForAdminsInfo
  );
  const {
    loadingGetParkingsForAdmins,
    getParkingsForAdminsData,
    errorGetParkingsForAdmins,
  } = getParkingsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_FOR_ADMINS_RESET });
    if (getParkingsForAdminsData) {
      setAllParkings(getParkingsForAdminsData);
      // setLoading(false);
    } else if (errorGetParkingsForAdmins) {
      toast.error(errorGetParkingsForAdmins);
      // setLoading(false);
    }
  }, [getParkingsForAdminsData, errorGetParkingsForAdmins]);

  useEffect(() => {
    if (userToEdit) {
      console.log(userToEdit);
      setName(userToEdit.name);
      setStatus(userToEdit.status);
      setRole(userToEdit.role);
      setParking(userToEdit.parking);
      setIotDeviceId(userToEdit.iotDeviceId);
    } else {
      setName("");
      setStatus(false);
      setIotDeviceId("");
    }
  }, [userToEdit]);

  const handleUpdate = () => {
    if (!name) {
      toast.error("Name is mandatory");
      return;
    }

    if (role === "manager" && parking.length === 0) {
      toast.error("Parking is mandatory");
      return;
    }

    if (role === "attendant" && parking.length !== 1) {
      toast.error("One Parking is mandatory");
      return;
    }

    dispatch(editUser(userToEdit._id, name, status, parking, iotDeviceId));
  };

  const editUserInfo = useSelector((state) => state.editUserInfo);
  const { loadingEditUser, editUserData, errorEditUser } = editUserInfo;

  useEffect(() => {
    dispatch({ type: EDIT_USER_RESET });
    if (editUserData) {
      toast.success(editUserData.msg);
      setUserToEdit(null);
      setTimeout(() => {
        dispatch(getUsers(page));
      }, 500);
    } else if (errorEditUser) {
      toast.error(errorEditUser);
    }
  }, [editUserData, errorEditUser]);

  const generatePasswordUserInfo = useSelector(
    (state) => state.generatePasswordUserInfo
  );
  const {
    loadingGeneratePasswordUser,
    generatePasswordUserData,
    errorGeneratePasswordUser,
  } = generatePasswordUserInfo;

  useEffect(() => {
    dispatch({ type: GENERATE_PASSWORD_USER_RESET });
    if (generatePasswordUserData) {
      toast.success(generatePasswordUserData.msg);
      setUserToEdit(null);
      setTimeout(() => {
        dispatch(getUsers(page));
      }, 500);
    } else if (errorGeneratePasswordUser) {
      toast.error(errorGeneratePasswordUser);
    }
  }, [generatePasswordUserData, errorGeneratePasswordUser]);

  const resetDeviceIdUserInfo = useSelector(
    (state) => state.resetDeviceIdUserInfo
  );
  const {
    loadingResetDeviceIdUser,
    resetDeviceIdUserData,
    errorResetDeviceIdUser,
  } = resetDeviceIdUserInfo;

  useEffect(() => {
    dispatch({ type: RESET_DEVICE_ID_USER_RESET });
    if (resetDeviceIdUserData) {
      toast.success(resetDeviceIdUserData.msg);
      setUserToEdit(null);
      setTimeout(() => {
        dispatch(getUsers(page));
      }, 500);
    } else if (errorResetDeviceIdUser) {
      toast.error(errorResetDeviceIdUser);
    }
  }, [resetDeviceIdUserData, errorResetDeviceIdUser]);

  const addUserHandler = () => {
    if (!name || !username || !role) {
      toast.error("All the fields are mandatory");
      return;
    }
    if ((role === "PARKING_ATTENDANT" || role === "GUARD") && !location) {
      toast.error("All the fields are mandatory");
      return;
    }
    if ((role === "ATTENDANT" || role === "MANAGER") && !parking) {
      toast.error("All the fields are mandatory");
      return;
    }
    dispatch(addUser(name, username, location, role, parking, iotDeviceId));
  };

  const addUserInfo = useSelector((state) => state.addUserInfo);
  const { loadingAddUser, addUserData, errorAddUser } = addUserInfo;

  useEffect(() => {
    dispatch({ type: ADD_USER_RESET });
    if (addUserData) {
      toast.success("User created successfully");
      setAddUserModdal(false);
      setName("");
      setUsername("");
      setRole("");
      setLocation([]);
      setParking([]);
      setIotDeviceId("");
      // setData([...data, addUserData])
      setTimeout(() => {
        dispatch(getUsers(page));
      }, 500);
    } else if (errorAddUser) {
      toast.error(errorAddUser);
    }
  }, [addUserData, errorAddUser]);

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "username", headerName: "Username", width: 200 },
    {
      field: "pin",
      headerName: "Pin",
      width: 200,
    },
    {
      field: "location",
      headerName: "Location",
      valueGetter: ({ value }) => (value ? value.name : "N/A"),
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: ({ value }) => (value === true ? "Yes" : "No"),
      width: 200,
    },
    { field: "deviceID", headerName: "Device ID", width: 250 },
    { field: "iotDeviceId", headerName: "IOT Device ID", width: 250 },
  ];

  useEffect(() => {
    if (role === "PARKING_ATTENDANT" || role === "GUARD") {
      let ldt = [];
      allLocations.forEach((el) => {
        if (el.businessType === "Corporate") {
          ldt.push(el);
        }
      });
      setFilteredAllLocations(ldt);
      let pdt = [];
      allParkings.forEach((el) => {
        if (el.location.businessType === "Corporate") {
          pdt.push(el);
        }
      });
      setFilteredAllParkings(pdt);
    } else if (role === "ATTENDANT" || role === "MANAGER") {
      let ldt = [];
      allLocations.forEach((el) => {
        if (el.businessType === "Unorganised") {
          ldt.push(el);
        }
      });
      setFilteredAllLocations(ldt);
      let pdt = [];
      allParkings.forEach((el) => {
        if (el.location.businessType === "Unorganised") {
          pdt.push(el);
        }
      });
      setFilteredAllParkings(pdt);
    } else {
      setFilteredAllLocations(allLocations);
      setFilteredAllParkings(allParkings);
    }
  }, [role]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="USERS" subtitle="List of users" />
        <Box>
          {adminInfo.type === "Admin" && (
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ fontSize: 14, marginRight: 4 }}
              disabled={
                loadingEditUser ||
                loadingGeneratePasswordUser ||
                loadingResetDeviceIdUser ||
                loadingAddUser
              }
              onClick={() => setAddUserModdal(true)}
            >
              Add User
            </Button>
          )}
          <Button
            type="submit"
            color="warning"
            variant="contained"
            sx={{ fontSize: 14 }}
            disabled={
              loadingEditUser ||
              loadingGeneratePasswordUser ||
              loadingResetDeviceIdUser ||
              loadingAddUser
            }
            onClick={() => setShowFiltersModal(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Table
          onPageChange={(newPage) => {
            setPage(newPage + 1);
          }}
          total={total}
          rows={data}
          columns={columns}
          page={page}
          onRowClick={
            adminInfo.type === "Admin" ? (row) => setUserToEdit(row.row) : null
          }
          getRowId={(row) => row._id}
        />
      )}
      {(userToEdit || addUserModal) && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setUserToEdit(null);
            setAddUserModdal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Edit User
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={2}
                >
                  <Typography variant="p" component="p">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    name="name"
                    sx={{ gridColumn: "span 4" }}
                    disabled={
                      loadingEditUser ||
                      loadingGeneratePasswordUser ||
                      loadingResetDeviceIdUser ||
                      loadingAddUser
                    }
                  />
                </Box>
                {addUserModal && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Username
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Username"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                      name="username"
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    />
                  </Box>
                )}
                {userToEdit && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Status
                    </Typography>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={(e) => setStatus(e.target.value)}
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Not Active</MenuItem>
                    </Select>
                  </Box>
                )}
                {addUserModal && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Role
                    </Typography>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={role}
                      label="Role"
                      onChange={(e) => setRole(e.target.value)}
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      <MenuItem value="PARKING_ATTENDANT">
                        Parking Attendant (Corporate)
                      </MenuItem>
                      <MenuItem value="GUARD">Guard (Corporate)</MenuItem>
                      <MenuItem value="ATTENDANT">
                        Attendant (Unorganised)
                      </MenuItem>
                      <MenuItem value="MANAGER">Manager (Unorganised)</MenuItem>
                    </Select>
                  </Box>
                )}
                {addUserModal &&
                  (role === "PARKING_ATTENDANT" || role === "GUARD") && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridColumn="span 4"
                      gap={1}
                      marginTop={-2}
                    >
                      <Typography variant="p" component="p">
                        Location
                      </Typography>
                      <Select
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={location}
                        label="Location"
                        onChange={(e) => setLocation(e.target.value)}
                        sx={{ gridColumn: "span 4" }}
                        disabled={
                          loadingEditUser ||
                          loadingGeneratePasswordUser ||
                          loadingResetDeviceIdUser ||
                          loadingAddUser
                        }
                      >
                        {filteredAllLocations.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name} - ({item.businessType})
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                {/* {addUserModal && */}
                {(role === "ATTENDANT" ||
                  role === "MANAGER" ||
                  role === "attendant" ||
                  role === "manager") && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Parking
                    </Typography>
                    <Select
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple={
                        role === "MANAGER" || role === "manager" ? true : false
                      }
                      value={parking}
                      label="Parking"
                      onChange={(e) =>
                        setParking(
                          role === "ATTENDANT"
                            ? [e.target.value]
                            : e.target.value
                        )
                      }
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      {filteredAllParkings.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name} - ({item.location.businessType})
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
                {role?.toUpperCase() === "PARKING_ATTENDANT" && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      IOT Device ID
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="IOT Device ID"
                      onChange={(e) => setIotDeviceId(e.target.value)}
                      value={iotDeviceId}
                      name="iotDeviceId"
                      sx={{ gridColumn: "span 4" }}
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box
                mt="20px"
                display="flex"
                justifyContent="space-between"
                sx={{ height: 42 }}
              >
                {userToEdit && (
                  <>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{ fontSize: 14 }}
                      onClick={handleUpdate}
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      Update
                    </Button>
                    <Button
                      type="submit"
                      color="error"
                      variant="contained"
                      sx={{ fontSize: 14 }}
                      onClick={() =>
                        dispatch(generatePasswordUser(userToEdit._id))
                      }
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      Password
                    </Button>
                    <Button
                      type="submit"
                      color="warning"
                      variant="contained"
                      sx={{ fontSize: 14 }}
                      onClick={() =>
                        dispatch(resetDeviceIdUser(userToEdit._id))
                      }
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      Reset DID
                    </Button>
                  </>
                )}
                {addUserModal && (
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: 14 }}
                    onClick={addUserHandler}
                    disabled={loadingAddUser}
                  >
                    Add
                  </Button>
                )}
              </Box>
            </div>
          </Box>
        </Modal>
      )}
      {showFiltersModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setShowFiltersModal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isNonMobile ? 800 : 350,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              Filters
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onChange={(e) => setFilterName(e.target.value)}
                      value={filterName}
                      name="name"
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Username
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Username"
                      onChange={(e) => setFilterUsername(e.target.value)}
                      value={filterUsername}
                      name="username"
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Role
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterRole}
                      label="Role"
                      onChange={(e) => setFilterRole(e.target.value)}
                      name="role"
                      disabled={
                        loadingEditUser ||
                        loadingGeneratePasswordUser ||
                        loadingResetDeviceIdUser ||
                        loadingAddUser
                      }
                    >
                      <MenuItem value="parking attendant">
                        Parking Attendant (Corporate)
                      </MenuItem>
                      <MenuItem value="guard">Guard (Corporate)</MenuItem>
                      <MenuItem value="attendant">
                        Attendant (Unorganised)
                      </MenuItem>
                      <MenuItem value="manager">Manager (Unorganised)</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setLoading(true);
                    setShowFiltersModal(false);
                    dispatch(
                      getUsers(page, filterUsername, filterName, filterRole)
                    );
                  }}
                  disabled={
                    loadingEditUser ||
                    loadingGeneratePasswordUser ||
                    loadingResetDeviceIdUser ||
                    loadingAddUser
                  }
                >
                  Apply
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Users;
